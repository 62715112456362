import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App.tsx";
import "./index.css";
import { ScreenModeProvider } from "./contexts/ScreenModeContext";
import { BrowserRouter as Router } from "react-router-dom";
import GlobalProvider from "./contexts/GlobalContext.tsx";

export const BACKEND_REST_URL = import.meta.env.VITE_BACKEND_REST_URL;

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <ScreenModeProvider>
      <Router>
        <GlobalProvider>
          <App />
        </GlobalProvider>
      </Router>
    </ScreenModeProvider>
  </React.StrictMode>,
);
