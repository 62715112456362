import React, { useState, useEffect, useRef, useCallback } from "react";
import { Container, Grid, Typography, CircularProgress } from "@mui/material";
import Record from "./Record.tsx";
import ActivityModal from "./viewActivity/ActivityModal.tsx";
import { RecordData, SessionRecordData } from "../pages/Sessions/types.ts";
import { useSearchContext } from "../contexts/SearchContext.tsx";
import { BACKEND_REST_URL } from '../main.tsx';
const RecordList: React.FC = () => {
  const [records, setRecords] = useState<RecordData[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [selectedRecord, setSelectedRecord] = useState<RecordData | SessionRecordData | null>(null);
  const [open, setModalOpen] = useState<boolean>(false);
  const { searchData } = useSearchContext();
  const [skip, setSkip] = useState<number>(0);
  const [total, setTotal] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const LIMIT = 50;

  const observerRef = useRef<IntersectionObserver | null>(null);
  const scrollContainerRef = useRef<HTMLDivElement | null>(null);

  const onClose = () => setModalOpen(false);

  const fetchRecords = useCallback(() => {
    const activityOrSession = searchData.show_activities
      ? "activities"
      : "sessions";

    let url = BACKEND_REST_URL + `/api/${activityOrSession}/search`;

    setLoading(true);
    fetch(`${url}?skip=${skip}&limit=${LIMIT}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(searchData),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok " + response.statusText);
        }
        return response.json();
      })
      .then((data) => {
        setTotal(data["total"]);
        if (searchData.show_activities) {
          data.activities = data.activities.filter((x : RecordData) => ![1, 2, 6, 7, 9].includes(x.id))
          data.total = data.activities.length
        }

        setRecords((prevRecords) =>
          skip === 0
            ? data[searchData.show_activities ? "activities" : "sessions"]
            : [
              ...prevRecords,
              ...data[searchData.show_activities ? "activities" : "sessions"],
            ],
        );
      })
      .catch((error) => setError("Error fetching data: " + error.message))
      .finally(() => setLoading(false));

  }, [searchData, skip]);

  useEffect(() => {
    setSkip(0); // Reset skip to 0 when searchData changes
    setRecords([]); // Clear records when searchData changes
  }, [searchData]);

  useEffect(() => {
    fetchRecords();
  }, [fetchRecords]);

  const openModal = (record: RecordData | SessionRecordData) => {
    setSelectedRecord(record);
    setModalOpen(true);
  };

  const lastRecordElementRef = useCallback(
    (node: HTMLElement | null) => {
      if (loading) return;

      if (!searchData.show_activities) return;
      if (observerRef.current) observerRef.current.disconnect();

      observerRef.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && records.length < total) {
          setSkip((prevSkip) => prevSkip + LIMIT);
        }
      });

      if (node) observerRef.current.observe(node);
    },
    [loading, records.length, total],
  );

  if (error) {
    return (
      <Container>
        <Typography variant="h6" color="error" align="center">
          {error}
        </Typography>
      </Container>
    );
  }

  return (
    <Container
      component="section"
      maxWidth="lg"
      sx={{ my: 4 }}
      ref={scrollContainerRef}
    >
      {records && records.length > 0 && (
        <Grid container spacing={4}>
          {records.map((record, index) => (
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              key={record.id}
              ref={index === records.length - 1 ? lastRecordElementRef : null}
            >

              <Record
                Description={record.description}
                UID={record.id}
                Name={record.name ? record.name : record.title!}
                onClick={() => openModal(record)}
              />
            </Grid>
          ))}
        </Grid>
      )}
      {loading && (
        <Container sx={{ display: "flex", justifyContent: "center", my: 4 }}>
          <CircularProgress />
        </Container>
      )}
      <ActivityModal
        open={open}
        onClose={onClose}
        record={selectedRecord || ({} as RecordData | SessionRecordData)}
      />
    </Container>
  );
};

export default RecordList;
