// GlobalProvider.tsx
import React, { ReactNode } from "react";
import { SearchProvider } from "./SearchContext";
import { FormProvider } from "./FormContext";
import { AuthProvider } from "./AuthContext";

interface GlobalProviderProps {
  children: ReactNode;
}

const GlobalProvider: React.FC<GlobalProviderProps> = ({ children }) => {
  return (
    <AuthProvider>
      <FormProvider>
        <SearchProvider>{children}</SearchProvider>
      </FormProvider>
    </AuthProvider>
  );
};

export default GlobalProvider;
