import { createTheme, ThemeProvider } from '@mui/material/styles';
import { ActivityForm } from '../components/createActivity/ActivityForm';

const defaultTheme = createTheme();

export const CreateActivityPage = () => {
    return (
        <ThemeProvider theme={defaultTheme}>
            <ActivityForm />
        </ThemeProvider>
    );
};

export default CreateActivityPage;
