import React, { useState } from "react";
import { Box, IconButton, useMediaQuery, useTheme } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

interface CarouselProps {
  children: React.ReactNode;
}

const Carousel: React.FC<CarouselProps> = ({ children }) => {
  const theme = useTheme();

  // Define breakpoints using Material-UI's `useMediaQuery` hook
  const isXs = useMediaQuery(theme.breakpoints.down("xs")); // Extra small screens
  const isSm = useMediaQuery(theme.breakpoints.between("xs", "sm")); // Small screens
  const isMd = useMediaQuery(theme.breakpoints.between("sm", "md")); // Medium screens
  const isLg = useMediaQuery(theme.breakpoints.between("md", "lg")); // Large screens
  const isXl = useMediaQuery(theme.breakpoints.up("lg")); // Extra large screens

  // Adjust item width and number of visible items based on screen size
  let itemWidth = 300;
  let visibleItems = 3;

  if (isXs) {
    itemWidth = 200; // Width for extra small screens
    visibleItems = 1;
  } else if (isSm) {
    itemWidth = 250; // Width for small screens
    visibleItems = 2;
  } else if (isMd) {
    itemWidth = 300; // Width for medium screens
    visibleItems = 2;
  } else if (isLg) {
    itemWidth = 350; // Width for large screens
    visibleItems = 3;
  } else if (isXl) {
    itemWidth = 400; // Width for extra large screens
    visibleItems = 4;
  }

  const [position, setPosition] = useState(0);
  const [dragStartX, setDragStartX] = useState<number | null>(null);
  const [dragOffset, setDragOffset] = useState(0);
  const [isDragging, setIsDragging] = useState(false);

  const totalItems = React.Children.count(children);
  const maxPosition = -(totalItems - visibleItems) * itemWidth;

  const handleMouseDown = (event: React.MouseEvent) => {
    setDragStartX(event.clientX);
    setIsDragging(true);
  };

  const handleMouseMove = (event: React.MouseEvent) => {
    if (dragStartX !== null) {
      const dragDiff = event.clientX - dragStartX;
      setDragOffset(dragDiff);
    }
  };

  const handleMouseUp = () => {
    if (dragStartX !== null) {
      snapToNearestItem();
      setDragStartX(null);
      setDragOffset(0);
      setIsDragging(false);
    }
  };

  const handleTouchStart = (event: React.TouchEvent) => {
    setDragStartX(event.touches[0].clientX);
    setIsDragging(true);
  };

  const handleTouchMove = (event: React.TouchEvent) => {
    if (dragStartX !== null) {
      const dragDiff = event.touches[0].clientX - dragStartX;
      setDragOffset(dragDiff);
    }
  };

  const handleTouchEnd = () => {
    if (dragStartX !== null) {
      snapToNearestItem();
      setDragStartX(null);
      setDragOffset(0);
      setIsDragging(false);
    }
  };

  const snapToNearestItem = () => {
    const totalDrag = position + dragOffset;
    let nearestIndex = Math.round(totalDrag / itemWidth);

    if (nearestIndex > 0) {
      nearestIndex = 0;
    }

    if (nearestIndex * itemWidth < maxPosition) {
      nearestIndex = Math.ceil(maxPosition / itemWidth);
    }

    setPosition(nearestIndex * itemWidth);
  };

  const handlePrevClick = () => {
    if (position + itemWidth <= 0) {
      setPosition(position + itemWidth);
    } else {
      setPosition(0);
    }
  };

  const handleNextClick = () => {
    if (position - itemWidth >= maxPosition) {
      setPosition(position - itemWidth);
    } else {
      setPosition(maxPosition);
    }
  };

  return (
    <Box
      className={`relative ${isDragging ? "dragging" : ""}`}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseUp}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
      sx={{
        userSelect: isDragging ? "none" : "auto",
        cursor: isDragging ? "grabbing" : "grab",
        overflow: "hidden",
        position: "relative",
        maxWidth: `${visibleItems * itemWidth}px`,
        margin: "auto",
      }}
    >
      {!isXs && !isSm && (
        <>
          <IconButton
            onClick={handlePrevClick}
            sx={{
              position: "absolute",
              left: 0,
              top: "50%",
              transform: "translateY(-50%)",
              zIndex: 1,
              backgroundColor: "rgba(255, 255, 255, 0.8)",
              "&:hover": {
                backgroundColor: "rgba(255, 255, 255, 1)",
              },
            }}
            disabled={position >= 0}
          >
            <ArrowBackIosIcon />
          </IconButton>

          <IconButton
            onClick={handleNextClick}
            sx={{
              position: "absolute",
              right: 0,
              top: "50%",
              transform: "translateY(-50%)",
              zIndex: 1,
              backgroundColor: "rgba(255, 255, 255, 0.8)",
              "&:hover": {
                backgroundColor: "rgba(255, 255, 255, 1)",
              },
            }}
            disabled={position <= maxPosition}
          >
            <ArrowForwardIosIcon />
          </IconButton>
        </>
      )}

      <Box
        className="flex space-x-4 transition-transform duration-300 ease-out"
        sx={{
          transform: `translateX(${position + dragOffset}px)`,
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default Carousel;
