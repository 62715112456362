import React, { useState, useEffect } from "react";
import { Card, CardContent, CardMedia, Typography } from "@mui/material";
import fallback_img from '../assets/fallback_img.webp';
import { BACKEND_REST_URL } from "../main";
import { useSearchContext } from "../contexts/SearchContext";

interface RecordProps {
  UID: number;
  Name: string;
  Description: string;
  onClick: () => void;
}

const Record: React.FC<RecordProps> = ({
  UID,
  Name,
  Description,
  onClick,
}) => {
  const [imageSrc, setImageSrc] = useState<string>(fallback_img);  // Default to fallback image
  const { searchData } = useSearchContext();

  const truncatedTextName = Name.length > 30 ? `${Name.slice(0, 30)}...` : Name;
  const truncatedTextDescription =
    Description.length > 100 ? `${Description.slice(0, 100)}...` : Description;

  useEffect(() => {
    const fetchThumbnail = async () => {
      try {
        // Step 1: Fetch the thumbnail image ID
        const responseThumbnail = await fetch(BACKEND_REST_URL + `/api/${searchData.show_activities ? "activities" : "session"}/${UID}/images/thumbnail`);

        if (responseThumbnail.ok) {
          const data = await responseThumbnail.json();

          if (data.id) {
            // Step 2: Stream the image using the image ID
            const imageResponse = await fetch(BACKEND_REST_URL + `/api/image/${data.id}`);

            if (imageResponse.ok) {
              // Set the image source as the URL for the streamed image
              setImageSrc(BACKEND_REST_URL + `/api/image/${data.id}`);
            } else {
              console.error('Failed to stream the image');
            }
          } else {
            console.error('No image found, using fallback');
          }
        } else {
          console.error('Failed to fetch thumbnail ID, using fallback');
        }
      } catch (error) {
        console.error('Error fetching image:', error);
      }
    };

    fetchThumbnail();
  }, [UID]);

  return (
    <Card sx={{ height: '100%', cursor: 'pointer' }} onClick={onClick}>
      <CardMedia
        component="img"
        height="140"
        image={imageSrc}  // Either the fetched image or fallback image
        alt={Name}
      />
      <CardContent>
        <Typography variant="h5" component="h3" gutterBottom>
          {truncatedTextName}
        </Typography>
        <Typography
          variant="body2"
          gutterBottom
          dangerouslySetInnerHTML={{ __html: truncatedTextDescription }} // is this the way?
        />
      </CardContent>
    </Card>
  );
};


export default Record;
