import React from "react";
import { Grid, Typography, Box } from "@mui/material";
import DownhillSkiingIcon from "@mui/icons-material/DownhillSkiing";
import HikingIcon from "@mui/icons-material/Hiking";
import SnowboardingIcon from "@mui/icons-material/Snowboarding";
import AirlineStopsIcon from "@mui/icons-material/AirlineStops";
import { useFormContext } from "../../contexts/FormContext";

const grenOptions = [
  { label: "Alpint", icon: <DownhillSkiingIcon fontSize="large" /> },
  { label: "Telemark", icon: <DownhillSkiingIcon fontSize="large" /> },
  { label: "Kombinert", icon: <DownhillSkiingIcon fontSize="large" /> },
  { label: "Langrenn", icon: <HikingIcon fontSize="large" /> },
  { label: "Hopp", icon: <AirlineStopsIcon fontSize="large" /> },
  { label: "Freestyle", icon: <SnowboardingIcon fontSize="large" /> },
];

const CategoryCard: React.FC<{
  icon: React.ReactNode;
  label: string;
  selected: boolean;
  onClick: () => void;
}> = ({ icon, label, selected, onClick }) => {
  return (
    <Box
      className={`flex flex-col items-center cursor-pointer p-4 rounded-md transition-colors duration-300 ${selected ? "bg-blue-200" : "hover:bg-gray-200"
        }`}
      onClick={onClick}
    >
      <Box className="text-4xl mb-2">{icon}</Box>
      <Typography variant="body1" className="mt-1">
        {label}
      </Typography>
    </Box>
  );
};


const BranchSelection: React.FC = () => {
  const { selectedBranches, handleBranchChange, errors } = useFormContext();


  return (
    // <ThemeProvider theme={theme}>
    <Box className="p-4  flex flex-col items-center">
      <Typography variant="h5" className="mb-4">
        Velg kategori for økten din
      </Typography>
      {errors.selectedBranches && <span style={{ color: 'red' }}>{errors.selectedBranches}</span>}
      <Grid
        container
        spacing={2}
        className='max-w-screen-md'
        justifyContent="center"
      >
        {grenOptions.map((option, index) => (
          <Grid item xs={6} sm={4} md={4} key={index}>
            <CategoryCard
              icon={option.icon}
              label={option.label}
              selected={selectedBranches.includes(option.label)}
              onClick={() => handleBranchChange(option.label)}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default BranchSelection;