import { Button, CircularProgress, Container, Drawer, Typography } from "@mui/material";
import Searchbar from "../Searchbar";
import { ActivityPickerDrawerProps, RecordData } from "../../pages/Sessions/types";
import fallback_img from "../../assets/fallback_img.webp";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const ActivityPickerDrawer: React.FC<ActivityPickerDrawerProps> = ({
    open,
    onClose,
    records,
    handleSelectActivity,
    handleDeleteActivity,
    selectedActivities,
    lastRecordElementRef,
    loading,
    handleSearchTextChange,
    scrollContainerRef,
}) => {

    const handleActivityClick = (activity: RecordData) => {
        // Check if the activity is already selected
        const isAlreadySelected = selectedActivities.some(selectedActivity => selectedActivity.id === activity.id);

        // If already selected, do nothing
        if (isAlreadySelected) {
            handleDeleteActivity(activity)
            return;
        }

        // Otherwise, select the activity
        handleSelectActivity(activity);
    };

    return (
        <Drawer anchor="right" open={open} onClose={onClose}>
            <div className="p-4 w-80">
                <Typography variant="h6" className="mb-4">
                    Velg en Aktivitet
                </Typography>
                <Searchbar
                    label="Søk etter øvelser"
                    onChange={(e) => handleSearchTextChange(e)}
                />
                <div ref={scrollContainerRef} className='py-4'>
                    {records &&
                        records.length > 0 &&
                        records.map((x, index) => {
                            const isSelected = selectedActivities.some(activity => activity.id === x.id);

                            return (
                                <div
                                    onClick={() => handleActivityClick(x)} // Use the new click handler
                                    ref={index === records.length - 1 ? lastRecordElementRef : null}
                                    key={x.id}
                                    className={`bg-white rounded-lg shadow-md p-4 text-center flex flex-col justify-between cursor-pointer hover:bg-gray-200 transition ${isSelected ? 'border-2 border-blue-500' : ''
                                        }`}
                                >
                                    <div className="relative">
                                        <img
                                            src={x.imageUrl ?? fallback_img}
                                            className="w-16 h-16 object-cover rounded-md mr-4"
                                        />
                                        {isSelected && (
                                            <CheckCircleIcon
                                                className="absolute top-0 right-0 text-blue-500"
                                                fontSize="large"
                                            />
                                        )}
                                    </div>
                                    <div className="flex-1">
                                        <Typography
                                            variant="h6"
                                            className="text-lg font-semibold mb-2"
                                        >
                                            {x.name}
                                        </Typography>
                                    </div>
                                </div>
                            );
                        })}
                    {loading && (
                        <Container
                            sx={{ display: "flex", justifyContent: "center", my: 4 }}
                        >
                            <CircularProgress />
                        </Container>
                    )}
                </div>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={onClose}
                    className="mt-8  w-full"
                >
                    Lukk
                </Button>
            </div>
        </Drawer>
    );
};

export default ActivityPickerDrawer;
