import React, { useState, useEffect } from "react";
import { Route, Routes, Link } from "react-router-dom";
import Home from "./pages/Home";
import { AppBar, Toolbar, Typography, Container } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { IconButtonWithText } from "./components/utils";
import { SignInPage } from "./pages/signin";
import CreateSession from "./pages/Sessions/CreateSession";
import { CreateActivityPage } from "./pages/CreateActivityPage";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { SnackbarProvider } from "./components/SnackbarProvider";
import { useAuth } from "./contexts/AuthContext";
import MyPage from "./pages/MyPage";

const App: React.FC = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth > 600);

  const { isLoggedIn, userData } = useAuth();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth > 600);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      {isMobile ? (
        <AppBar
          position="sticky"
          z-index="10"
          sx={{ backgroundColor: "#fff", color: "#343a40", mb: 4 }}
        >
          <Container>
            <Toolbar disableGutters>
              {/* Wrap the Typography in a Link */}
              <Typography variant="h6" component={Link} to="/" sx={{ flexGrow: 1, textDecoration: "none", color: "inherit" }}>
                Aktivitetsplanlegger
              </Typography>
              {isLoggedIn ? <IconButtonWithText
                icon={<AddCircleIcon />}
                text="Lag økt"
                to="/createSession"
              /> : <></>}
              {isLoggedIn ? <IconButtonWithText
                icon={<AddCircleIcon />}
                text="Lag øvelse"
                to="/createActivity"
              /> : <></>}
              <IconButtonWithText icon={<HomeIcon />} text="Hjem" to="/" />
              <IconButtonWithText
                icon={<AccountCircleIcon />}
                text={isLoggedIn ? userData.user_name : "Logg inn"}
                to={isLoggedIn ? "/my-page" : "/signin"}
              />
            </Toolbar>
          </Container>
        </AppBar>
      ) : (
        <AppBar
          position="fixed"
          sx={{
            backgroundColor: "#fff",
            color: "#343a40",
            mb: 0,
            bottom: 0,
            top: "auto",
          }}
        >
          <Container>
            <Toolbar disableGutters>
              <Typography variant="h6" component={Link} to="/home" sx={{ flexGrow: 1, textDecoration: "none", color: "inherit" }}>
                Aktivitetsplanlegger
              </Typography>
              <IconButtonWithText
                icon={<AddCircleIcon />}
                text="Lag økt"
                to="/createSession"
              />
              <IconButtonWithText
                icon={<AddCircleIcon />}
                text="Lag øvelse"
                to="/createActivity"
              />
              <IconButtonWithText icon={<HomeIcon />} text="Hjem" to="/" />
              <IconButtonWithText
                icon={<AccountCircleIcon />}
                text={isLoggedIn ? userData.user_name : "Logg inn"}
                to={isLoggedIn ? "/my-page" : "/signin"}
              />
            </Toolbar>
          </Container>
        </AppBar>
      )}
      <SnackbarProvider>

        <Routes>
          <Route path="/createActivity" element={<CreateActivityPage />} />
          <Route path="/" element={<Home />} />
          <Route path="/signin" element={<SignInPage />} />
          <Route path="/my-page" element={<MyPage />} />
          <Route
            path="/createSession"
            element={<CreateSession />}
          />
        </Routes>
      </SnackbarProvider>
    </>
  );
};

export default App;