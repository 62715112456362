import React, { useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material"; // Import arrow icons
import { RecordData } from "../../pages/Sessions/types";
import fallback_img from "../../assets/fallback_img.webp";
import { QuillViewer } from "../createActivity/richEditor";

interface ActivityCardProps {
    activity: RecordData;
    onDelete?: () => void;
    onClick?: () => void;
}

const ActivityCard: React.FC<ActivityCardProps> = ({ activity, onDelete, }) => {
    const [showDescription, setShowDescription] = useState<boolean>(false);

    const handleToggleDescription = () => {
        setShowDescription(!showDescription);
    };

    return (
        <div className="relative aspect-square bg-white rounded-lg shadow-md p-4 text-center flex flex-col justify-between">
            <img
                src={activity.imageUrl || fallback_img}
                alt={activity.name}
                className="w-full aspect-square object-cover rounded-t-lg"
            />

            <h3 className="mt-2 text-lg font-semibold text-gray-800 truncate">
                {activity.name}
            </h3>

            {showDescription && (
                <QuillViewer content={activity.description} />

            )}

            {onDelete && (
                <div className="flex justify-center space-x-4 items-center mt-2">
                    <button
                        type="button"
                        className="flex items-center justify-center text-white bg-red-500 rounded-full w-10 h-10 hover:bg-red-600 transition"
                        onClick={onDelete}
                    >
                        <DeleteIcon />
                    </button>
                </div>
            )}

            <div className="flex justify-center space-x-4 items-center mt-2">
                <button
                    type="button"
                    className="flex items-center justify-center text-white bg-gray-200 rounded-full w-10 h-10 hover:bg-gray-400 transition"
                    onClick={handleToggleDescription}
                >
                    {showDescription ? <ArrowDropUp /> : <ArrowDropDown />}
                </button>
            </div>
        </div>
    );
};

export default ActivityCard;