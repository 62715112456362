import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Slide,
  SwipeableDrawer,
  Button,
  Container,
  Tabs,
  Tab,
} from "@mui/material";
import Filter from "./LandingFilters/Filter";
import Searchbar from "./Searchbar";
import { ToggleSwitch } from "./LandingFilters/switch/ToggleSwitch";
import TuneIcon from "@mui/icons-material/Tune";
import { useScreenMode } from "../contexts/ScreenModeContext";
import { useSearchContext } from "../contexts/SearchContext";

interface HideOnScrollProps {
  children: React.ReactElement;
  onPastChange: (isPast: boolean) => void;
}

function HideOnScroll({ children, onPastChange }: HideOnScrollProps) {
  const [show, setShow] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [isPast, setIsPast] = useState(true);

  const handleScroll = () => {
    const currentScrollY = window.scrollY;

    if (currentScrollY < lastScrollY) {
      setShow(true);
    } else if (currentScrollY > lastScrollY && currentScrollY > 1150) {
      setShow(false);
      setIsPast(true);
    }

    setLastScrollY(currentScrollY);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [lastScrollY]);

  useEffect(() => {
    onPastChange(show);
  }, [isPast, onPastChange]);

  return (
    <Slide appear={false} direction="down" in={show && isPast}>
      <Container
        sx={{
          position: "sticky",
          top: 64,
        }}
      >
        {children}
      </Container>
    </Slide>
  );
}

const SearchSlideContainer = () => {
  const { searchData, setSearchData } = useSearchContext();
  const [showComponentOne, setShowComponentOne] = useState(false);
  const [showSlider, setShowSlider] = useState(false);
  const [showActivity, setShowActivity] = useState(1);
  const { isMobile } = useScreenMode();

  useEffect(() => {
    if (showSlider) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [showSlider]);

  const handleToggleSlider = () => {
    setShowSlider(!showSlider);
  };

  const handleTabChange = (_: React.SyntheticEvent, value: number) => {
    setShowActivity(value);
    setSearchData({
      ...searchData,
      show_activities: value == 0,
    });
  };

  const handleSearchTextChange = (searchText: string) => {
    setSearchData({
      ...searchData,
      plaintext: searchText,
    });
  };

  const handleFilterChange = (filter: Record<string, string[]>) => {
    setSearchData({
      ...searchData,
      ...filter,
    });
  };

  return (
    <>
      {isMobile ? (
        <>
          <Box
            sx={{
              zIndex: (theme) => theme.zIndex.drawer + 1,
              backgroundColor: "#f8f9fa",
              position: "fixed",
              width: "100%",
              top: 0,
            }}
          >
            <Box>
              <Searchbar
                label="Søk etter øvelser eller økter"
                onChange={(searchText) => handleSearchTextChange(searchText)}
              />
            </Box>
            <Container>
              <Button
                variant="contained"
                onClick={handleToggleSlider}
                sx={{
                  position: "absolute",
                  top: 88,
                  zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
              >
                <TuneIcon />
              </Button>
            </Container>
          </Box>
          <SwipeableDrawer
            anchor="bottom"
            open={showSlider}
            onClose={() => setShowSlider(false)}
            onOpen={() => setShowSlider(true)}
            disableBackdropTransition={true}
            disableDiscovery={true}
            sx={{
              "& .MuiDrawer-paper": {
                height: "100%",
                top: 350,
              },
            }}
          >
            <Box
              sx={{
                height: "100%",
                overflowY: "auto",
                paddingBottom: "2rem",
              }}
            >
              <Filter />
              <Box sx={{ textAlign: "center", padding: 2 }}>
                <Typography variant="h6">Søk gjennom</Typography>
                <ToggleSwitch
                  label1="Øvelser"
                  label2="Økter"
                  onChange={setShowComponentOne}
                  checked={showComponentOne}
                />
              </Box>
            </Box>
          </SwipeableDrawer>
        </>
      ) : (
        <HideOnScroll onPastChange={() => {}}>
          <Container
            sx={{
              backgroundColor: "white",
              boxShadow: 1,
            }}
          >
            <Searchbar
              label="Søk etter øvelser eller økter"
              onChange={(searchText) => handleSearchTextChange(searchText)}
            />
            <Filter
              onChangeBranch={(value) => handleFilterChange({ branch: value })}
              onChangeDisabilities={(value) =>
                handleFilterChange({ disabilities: value })
              }
              onChangeEnvironment={(value) =>
                handleFilterChange({ environment: value })
              }
              onChangeFocusGroup={(value) =>
                handleFilterChange({ focusGroup: value })
              }
              onChangeCategories={(value) =>
                handleFilterChange({ category: value })
              }
              onChangeSkillLevel={(value) =>
                handleFilterChange({ skillLevel: value })
              }
            />
            <Box sx={{ padding: 1, textAlign: "center" }}>
              <Tabs
                value={showActivity}
                onChange={handleTabChange}
                centered
                variant="fullWidth"
              >
                <Tab label="Øvelser" />
                <Tab label="Økter" />
              </Tabs>
            </Box>
          </Container>
        </HideOnScroll>
      )}
    </>
  );
};

export default SearchSlideContainer;
