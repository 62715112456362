import React from "react";
import { IconButton, Typography } from "@mui/material";
import { Link } from "react-router-dom";

interface IconButtonWithTextProps {
  icon: React.ReactNode;
  text: string;
  to: string;
}

export const IconButtonWithText: React.FC<IconButtonWithTextProps> = ({
  icon,
  text,
  to,
}) => {
  return (
    <IconButton
      component={Link}
      to={to}
      sx={{
        display: "flex",
        flexDirection: "column",
        p: 1,
        textAlign: "center",
      }}
    >
      {icon}
      <Typography variant="caption">{text}</Typography>
    </IconButton>
  );
};
