import React, { useState, useEffect, useRef } from "react";
import { Box, Typography, IconButton, Button } from "@mui/material";
import { ArrowBack, ArrowForward } from "@mui/icons-material";

interface ImageCarouselProps {
  images: string[];
  isMobile?: boolean;
  onThumbnailChange: (index: number) => void;
}

const ImageCarousel: React.FC<ImageCarouselProps> = ({
  images,
  isMobile,
  onThumbnailChange,
}) => {
  const [currentIndex, setCurrIndex] = useState(0);
  const [isAnimating, setIsAnimating] = useState(false);
  const [thumbnailIndex, setThumbnailIndex] = useState(0);
  const [imagesUpdated, setImagesUpdated] = useState(false); // State to track if images were updated due to adding new images
  const sliderRef = useRef<HTMLDivElement>(null);

  const handleNext = () => {
    if (currentIndex < images.length - 1 && !isAnimating) {
      setIsAnimating(true);
      setCurrIndex((prevIndex) => (prevIndex + 1) % images.length);
    }
  };

  const handlePrevious = () => {
    if (currentIndex > 0 && !isAnimating) {
      setIsAnimating(true);
      setCurrIndex(
        (prevIndex) => (prevIndex - 1 + images.length) % images.length,
      );
    }
  };

  useEffect(() => {
    if (isAnimating) {
      setTimeout(() => {
        setIsAnimating(false);
      }, 500);
    }
  }, [isAnimating]);

  useEffect(() => {
    if (sliderRef.current) {
      sliderRef.current.scrollTo({
        left: currentIndex * sliderRef.current.clientWidth,
        behavior: "smooth",
      });
    }
  }, [currentIndex]);

  useEffect(() => {
    if (imagesUpdated && images.length > 0) {
      setCurrIndex(images.length - 1);
      setImagesUpdated(false); // Reset the flag after updating the current index
    }
  }, [images, imagesUpdated]);

  const handleClickIndicator = (index: number) => {
    setCurrIndex(index);
  };

  const handleThumbnailClick = (index: number) => {
    setThumbnailIndex(index);
    onThumbnailChange(index);
  };

  useEffect(() => {
    if (images.length > 0 && !imagesUpdated) {
      setImagesUpdated(true); // Set the flag when images are updated due to adding new images
    }
  }, [images]);

  if (images.length === 0) {
    return (
      <Box
        sx={{
          backgroundColor: "primary.main",
          borderRadius: 2,
          width: "100%",
          height: "416px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position: "relative",
          overflow: "hidden",
        }}
      >
        <Typography variant="body1" color="#fff">
          Klikk for å legge til bilde
        </Typography>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        position: "relative",
        objectFit: "cover",
        width: "100%",
        overflow: "hidden",
      }}
    >
      <Box
        ref={sliderRef}
        sx={{
          display: "flex",
          transition: "transform 0.5s ease-in-out",
          transform: `translateX(-${currentIndex * 100}%)`,
        }}
      >
        {images.map((image, index) => (
          <Box
            key={index}
            sx={{
              position: "relative",
              flexShrink: 0,
              width: "100%",
            }}
          >
            <img
              src={image}
              alt={`Image ${index + 1}`}
              style={{
                objectFit: "cover",
                borderRadius: 1,
                width: "100%",
                height: isMobile ? "200px" : "416px",
              }}
            />
            <Box
              sx={{
                position: "absolute",
                top: 8,
                right: 8,
                padding: "2px",
                minWidth: 0,
              }}
            >
              <Button
                variant="outlined"
                onClick={() => handleThumbnailClick(index)}
                sx={{
                  height: "100%",
                  backgroundColor: thumbnailIndex === index ? "blue" : "white",
                  color: thumbnailIndex === index ? "white" : "black",
                  borderColor: thumbnailIndex === index ? "blue" : "black",
                  "&:hover": {
                    backgroundColor:
                      thumbnailIndex === index ? "darkblue" : "#e0e0e0",
                    borderColor:
                      thumbnailIndex === index ? "darkblue" : "black",
                  },
                }}
              >
                {thumbnailIndex === index
                  ? "Forsidebilde"
                  : "Sett som forsidebilde"}
              </Button>
            </Box>
          </Box>
        ))}
      </Box>
      {images.length > 1 && (
        <>
          <IconButton
            onClick={handlePrevious}
            sx={{
              height: "100%",
              position: "absolute",
              top: "50%",
              transform: "translateY(-50%)",
              backgroundColor: "rgba(0, 0, 0, 0)",
              outline: "none",
              border: "none",
              color: "white",
              zIndex: 2,
              borderRadius: 0,
              "&:hover": {
                opacity: 1,
                visibility: "visible",
                backgroundColor: "rgba(0, 0, 0, 0.5)",
              },
            }}
          >
            <ArrowBack />
          </IconButton>
          <IconButton
            onClick={handleNext}
            sx={{
              position: "absolute",
              right: 0,
              top: 0,
              bottom: 0,
              color: "white",
              backgroundColor: "rgba(0, 0, 0, 0.0)",
              borderRadius: 0,
              zIndex: 2,
              "&:hover": {
                opacity: 1,
                visibility: "visible",
                backgroundColor: "rgba(0, 0, 0, 0.5)",
              },
            }}
          >
            <ArrowForward />
          </IconButton>
        </>
      )}
      <Box
        sx={{
          position: "absolute",
          bottom: "10px",
          left: "50%",
          transform: "translateX(-50%)",
          display: "flex",
          gap: "5px",
          zIndex: 1,
        }}
      >
        {images.map((_, index) => (
          <IconButton
            key={index}
            onClick={() => handleClickIndicator(index)}
            sx={{
              width: "12px",
              height: "12px",
              padding: "0",
              borderRadius: "50%",
              backgroundColor: index === currentIndex ? "#fff" : "transparent",
              border: "1px solid #fff",
            }}
          />
        ))}
      </Box>
    </Box>
  );
};

export default ImageCarousel;
