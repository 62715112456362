import React, { ChangeEvent, useState } from 'react';
import {
  Container,
  Button,
  Box,
  Typography,
  Grid,
} from '@mui/material';
import { CloudUpload, Delete } from '@mui/icons-material';

import {
  InputTextField,
  TitleInput,
  InputVideoLink
} from './activityFormSelectors';
import Filter from '../LandingFilters/Filter';
import { RecordDataPost, ImageDataPost, RecordData, RequiredInputRecordDataPost } from '../../pages/Sessions/types';
import ImageCarousel from './carousel/ImageCarousel';
import { QuillEditor, QuillViewer } from './richEditor';
import { useSnackbar } from '../SnackbarProvider';
import { useNavigate } from 'react-router';
import { BACKEND_REST_URL } from '../../main';

interface ActivityFormProps {
  activity?: RecordData;
  readOnly?: boolean;
}

const initialActivity: RequiredInputRecordDataPost = {
  name: '',
  description: '',
  branches: [],
  environments: [],
  skill_levels: [],
  categories: [],
  focus_groups: [],
};

type ErrorType = {
  [key: string]: string;
};
const requiredFields: { [key in keyof RequiredInputRecordDataPost]: string } = {
  name: 'Øvelsesnavn',
  skill_levels: 'Ferdighetsnivå',
  categories: 'Nøkkelord',
  focus_groups: 'Fokus gruppe',
  description: 'Beskrivelse',
  branches: 'Gren',
  environments: 'Miljø',
};
const validateActivity = (activity: RequiredInputRecordDataPost): ErrorType => {
  const errors: ErrorType = {};

  Object.entries(requiredFields).forEach(([key]) => {
    const field = key as keyof RequiredInputRecordDataPost;
    const fieldValue = activity[field];

    if (!fieldValue || (Array.isArray(fieldValue) && fieldValue.length === 0)) {
      errors[field] = `* obligatorisk`;
    }
  });

  return errors;
};

export const ActivityForm: React.FC<ActivityFormProps> = ({
  activity,
  readOnly = false,
}) => {

  const [currentIndex] = useState(0);
  const { showSnackbar } = useSnackbar();
  const [activityPost, setActivityPost] = useState<RecordDataPost>(initialActivity)
  const [errors, setErrors] = useState<ErrorType>({});
  const [images, setImages] = useState<ImageDataPost[]>([])
  const navigate = useNavigate()

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setActivityPost((prev: RecordDataPost) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const newErrors: ErrorType = validateActivity(activityPost);
    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      try {
        await handlePost(activityPost, images);
      } catch (error) {
        console.error(error)
      }
    }
  };

  const handlePost = async (activity: RecordDataPost, images: ImageDataPost[]) => {

    const activityUrl = BACKEND_REST_URL + '/api/activity';
    try {
      const token = localStorage.getItem('access_token') || sessionStorage.getItem('access_token');
      console.log('Token:', token)

      if (!token) {
        navigate('/signin')
      }
      const response = await fetch(activityUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`, // Add the Bearer token here
        },
        body: JSON.stringify(activity),
      });
      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

      const activityData = await response.json();

      if (images.length > 0) {
        for (const { file, isThumbnail } of images) {
          const formData = new FormData();
          formData.append('file', file);
          const imageUrl = BACKEND_REST_URL + `/api/activity/${activityData.id}/images?is_thumbnail=${isThumbnail}`;
          const imageResponse = await fetch(imageUrl, {
            method: 'POST',
            body: formData,
            headers: {
              'Authorization': `Bearer ${token}`, // Add the Bearer token here
            },
          });

          if (!imageResponse.ok) {
            throw new Error(`Error uploading image: ${imageResponse.statusText}`);
          }
        }
      }

      showSnackbar('Ny aktivitet lagret!', 'success');
      navigate('/', { state: { showSnackbar: true } });
    } catch (error) {
      console.error('Error posting activity data:', error);
      throw error;
    }
  };

  const handleImageUpload = (newFiles: FileList) => {
    const fileArray = Array.from(newFiles);

    const newImagePosts: ImageDataPost[] = fileArray.map((file, index) => ({
      file: file,
      isThumbnail: images.length === 0 && index === 0,
    }));

    // Update the images array in the parent component
    setImages([...images, ...newImagePosts]);
  };
  const addImage = () => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*';
    input.multiple = true;
    input.onchange = (e: any) => handleImageUpload(e.target.files);
    input.click();
  };

  const deleteImageAtIndex = (indexToDelete: number) => {
    const updatedImages = images.filter((_, index) => index !== indexToDelete);
    setImages(updatedImages);
  };

  const deleteImages = () => {
    if (images.length > 0) {
      deleteImageAtIndex(currentIndex);
    }
  };

  const handleThumbnailChange = (index: number) => {
    const updatedImages = images.map((image, idx) => ({
      ...image,
      isThumbnail: idx === index,
    }));
    setImages(updatedImages);
  };

  const isMobile = window.innerWidth <= 600;

  return (
    <Container component="main" maxWidth="lg">
      <Grid container spacing={2} sx={{ marginBottom: 2 }}>
        <Grid item xs={12}>
        </Grid>
        {readOnly ? (
          <TitleInput
            name="name"
            title={activity?.name}
          />
        ) : (
          <Grid item xs={12}>
            <Grid paddingBottom={2}>
              <Typography component="h1" variant="h5">
                Lag øvelse
              </Typography>
            </Grid>
            <TitleInput
              name="name"
              title={activity?.name}
              onChange={handleInputChange}
              error={!!errors.name}
              helperText={errors.name}
            />
          </Grid>
        )}
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Filter
            stackVertically={true}
            error={!!errors.branch || !!errors.environment || !!errors.skillevel || !!errors.focusGroup || !!errors.categories || !!errors.disabilities}
            readOnly={readOnly}
            activity={activity}
            onChangeBranch={(selectedOptions) => setActivityPost((prev) => ({ ...prev, branches: selectedOptions }))}
            onChangeEnvironment={(selectedOptions) => setActivityPost((prev) => ({ ...prev, environments: selectedOptions }))}
            onChangeSkillLevel={(selectedOptions) => setActivityPost((prev) => ({ ...prev, skill_levels: selectedOptions }))}
            onChangeFocusGroup={(selectedOptions) => setActivityPost((prev) => ({ ...prev, focus_groups: selectedOptions }))}
            onChangeCategories={(selectedOptions) => setActivityPost((prev) => ({ ...prev, categories: selectedOptions }))}
            onChangeDisabilities={(selectedOptions) => setActivityPost((prev) => ({ ...prev, disabilities: selectedOptions }))}
          />

        </Grid>
        {/*Image*/}
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              backgroundColor: 'primary.main',
              borderRadius: 2,
              width: '100%',
              height: isMobile ? '200px' : '416px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: readOnly ? 'default' : 'pointer',
              overflow: 'hidden',
            }}
            onClick={() => {
              if (!readOnly && images.length < 1) {
                addImage();
              }
            }}
          >
            {readOnly && (
              <Typography variant="body1" color="textSecondary">
                Ingen bilder å vise
              </Typography>
            )}

            {images.length === 0 && !readOnly && (
              <Typography variant="body1" color="#fff">
                Klikk for å laste opp bildet
              </Typography>
            )}

            {images.length > 0 && (
              <ImageCarousel
                images={images.map(image => URL.createObjectURL(image.file))}
                isMobile={isMobile}
                onThumbnailChange={handleThumbnailChange}
              />
            )}
          </Box>

          {images.length > 0 && !readOnly && (
            <Grid container spacing={2} sx={{ mt: 0, justifyContent: 'space-between', alignItems: 'center' }}>
              <Grid item>
                <Button
                  variant="outlined"
                  startIcon={<Delete />}
                  onClick={deleteImages}
                  sx={{ height: '100%' }}
                >
                  Slett
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  startIcon={<CloudUpload />}
                  onClick={addImage}
                  sx={{ height: '100%' }}
                >
                  Legg til bilde
                </Button>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <Grid item xs={12}>
              {readOnly ? (
                <QuillViewer content={activity?.description || ''} />
              ) : (
                <QuillEditor value={activity?.description || ''} onChange={(value) => { setActivityPost((prev) => ({ ...prev, description: value })) }} />
              )}
            </Grid>
            {errors.description && (
              <Typography variant="body2" color="error">
                {errors.description}
              </Typography>
            )}
          </Box>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <InputTextField
                name="equipment"
                headline='Utstyr'
                label="Nødvendig utstyr (valgfritt)"
                value={(activityPost.equipment ?? []).join(', ')}
                onChange={(e) =>
                  setActivityPost((prev) => ({
                    ...prev,
                    equipment: e.target.value.split(',').map((item) => item.trim()),
                  }))
                }
                readOnly={readOnly}
              />
              <InputTextField
                name="tips"
                headline='Tips'
                label="Tips (valgfritt)"
                value={(activity ? activity.tips : activityPost.tips) || ''}
                onChange={handleInputChange}
                readOnly={readOnly}
              />
              <InputTextField
                name="adaptations"
                headline='Tilpasninger'
                label="Tilpasninger (valgfritt)"
                value={(activity ? activity.adaptations : activityPost.adaptations) || ''}
                onChange={handleInputChange}
                readOnly={readOnly}
              />

              <InputVideoLink
                name="videoUrl"
                headline="Video link"
                label="Video URL (valgfritt)"
                value={(activity ? activity.videoUrl : activityPost.videoUrl) || ''}
                onChange={handleInputChange}
                readOnly={readOnly}
              />
            </Grid>
            {!readOnly && (
              <Button type="submit" variant="contained" onClick={handleSubmit} sx={{ mt: 3, mb: 2 }}>
                Publiser
              </Button>
            )}
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};
