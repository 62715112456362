import React from "react";
import {
    Box,
    Button,
    Step,
    StepLabel,
    Stepper,

} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useFormContext } from "../../contexts/FormContext";

import SectionStep from "./SectionStep";
import BranchSelection from "./BranchSelection";
import ConclusionStep from "./ConclusionStep";
import InformationStep from "./InformationStep";


const theme = createTheme({
    palette: {
        primary: {
            main: "#0055a5",
        },
        secondary: {
            main: "#00aaff",
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: "none",
                    borderRadius: 8,
                },
            },
        },
    },
});

const CreateSession: React.FC = () => {
    const {
        sections,
        validateForm,
        step,
        setActiveStep,
    } = useFormContext();



    const steps = [
        "Gren",
        "Informasjon",
        ...Array(sections).fill("Seksjon"),
        "Avslutning",
    ];

    const handleNext = () => {
        if (validateForm()) {
            setActiveStep(step + 1);
        }
    };

    const handleBack = () => {
        setActiveStep(step - 1);
    };

    return (
        <ThemeProvider theme={theme}>
            <Box className="p-4 bg-gray-100 h-screen flex flex-col items-center">
                <Stepper activeStep={step} className="mb-4">
                    {steps.map((label, index) => (
                        <Step key={index}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
                <Box
                    className="bg-white p-6 rounded-lg shadow-md max-w-xl w-full"
                >
                    {step == 0 && <BranchSelection />}
                    {step === 1 && <InformationStep />}
                    {step > 1 && step < steps.length - 1 && (
                        <SectionStep step={step - 1} />
                    )}

                    {step === steps.length - 1 && (<ConclusionStep />)}

                    <Box className="flex justify-between mt-4">
                        <Button disabled={step === 0} onClick={handleBack}>
                            Tilbake
                        </Button>
                        {step < steps.length - 1 && (
                            <Button variant="contained" color="primary" onClick={handleNext}>
                                Neste
                            </Button>
                        )}
                    </Box>
                </Box>
            </Box>
        </ThemeProvider>
    );
};

export default CreateSession;
