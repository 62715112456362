import React, { useEffect, useState } from 'react';
import {
  Typography,
  Grid,
  TextField,
  Box
} from '@mui/material';

interface TitleInputProps {
  name: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  error?: boolean;
  helperText?: string;
  title?: string;
}

interface ActivityTextFieldProps {
  name: string;
  label: string;
  value: string | string[];
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  error?: boolean;
  multiline?: boolean;
  rows?: number;
  readOnly?: boolean;
  headline?: string;
}

export const TitleInput: React.FC<TitleInputProps> = ({
  name,
  onChange,
  error = false,
  helperText,
  title,

}) => {
  const isMobile = window.innerWidth <= 600;

  return (
    <Grid item xs={12}>
      <Box
        sx={{
          display: 'flex',
        }}
      >
        {onChange ? (
          <Typography
            component="div"
            sx={{
              width: '100%',
            }}
          >
            <TextField
              name={name}
              value={title}
              onChange={onChange}
              error={error}
              helperText={helperText}
              placeholder="Øvelsesnavn *"
              InputProps={{
                style: {
                  fontSize: isMobile ? '1rem' : '2rem',
                },
              }}
              fullWidth
            />
          </Typography>
        ) : (
          <Typography
            component="div"
            sx={{
              width: '100%',
              fontSize: isMobile ? '1rem' : '2rem',
              padding: '10px 0',
              borderBottom: '1px solid rgba(0, 0, 0, 0.23)',
            }}
          >
            {title}
          </Typography>
        )}
      </Box>
    </Grid>
  );
};


export const InputTextField: React.FC<ActivityTextFieldProps> = ({
  name,
  label,
  value,
  onChange,
  error = false,
  multiline = false,
  rows,
  readOnly = false,
  headline,
}) => (
  <Grid item xs={12}>
    {readOnly ? (
      <Typography
        variant="body1"
        sx={{
          padding: '10px 0',
          borderBottom: '1px solid rgba(0, 0, 0, 0.23)',
        }}
      >

        <strong>{headline}:</strong> {Array.isArray(value) ? value.join(', ') : value}
      </Typography>
    ) : (
      <TextField
        name={name}
        label={label}
        value={value || ''}
        onChange={onChange}
        error={error}
        multiline={multiline}
        rows={rows}
        fullWidth
        disabled={readOnly}
      />
    )}
  </Grid>
);


interface InputVideoLinkProps {
  name: string;
  label: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  readOnly?: boolean;
  headline?: string;
}

export const InputVideoLink: React.FC<InputVideoLinkProps> = ({ label, value, onChange, readOnly = false }) => {
  const [isValidVideoUrl, setIsValidVideoUrl] = useState(false);
  const [isYouTubeVideo, setIsYouTubeVideo] = useState(false);

  useEffect(() => {
    const regex = /^(http(s)?:\/\/)?((w){3}.)?youtu(be|.be)?(\.com)?\/.+/;
    setIsValidVideoUrl(regex.test(value));

    setIsYouTubeVideo(value.includes('youtube.com') || value.includes('youtu.be'));
  }, [value]);


  function getYoutubeId(url: string): string {
    const match = url.match(/[?&]([^=#]+)=([^&#]*)/);
    return match ? match[2] : '';
  }

  return (
    <Grid item xs={12}>
      {readOnly ? (
        <Box mb={2} sx={{ position: 'relative', objectFit: 'cover', paddingTop: '56.25%' }}>
          {isValidVideoUrl && isYouTubeVideo && (
            <iframe
              src={`https://www.youtube.com/embed/${getYoutubeId(value)}`}
              title="Embedded Video"
              allowFullScreen
              style={{ position: 'absolute', width: '100%', height: '100%', top: '0', left: '0' }}
            />
          )}
        </Box>
      ) : (
        <Box>
          {isValidVideoUrl && isYouTubeVideo && (
            <Box mb={2} sx={{ position: 'relative', objectFit: 'cover', paddingTop: '56.25%' }}>
              <iframe
                src={`https://www.youtube.com/embed/${getYoutubeId(value)}`}
                title="Embedded Video"
                allowFullScreen
                style={{ position: 'absolute', width: '100%', height: '100%', top: '0', left: '0' }}
              />
            </Box>
          )}
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <TextField
              name="videoUrl"
              label={label}
              value={value}
              onChange={onChange}
              fullWidth
              disabled={readOnly}
            />
          </Box>
        </Box>
      )}
    </Grid>
  );
};