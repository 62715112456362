import React from "react";
import { Divider, Typography } from "@mui/material";
import ActivityCard from "./ActivityCard";
import { RecordData } from "../../pages/Sessions/types";

interface SectionStepProps {
    sectionTitle: string;
    selectedActivities: RecordData[];
    onClick?: () => void;
    onDelete?: () => void;

}

const SectionStepGrid: React.FC<SectionStepProps> = ({ sectionTitle, selectedActivities ,onClick,onDelete}) => (
    <div className="container mx-auto p-4 lg:p-6 max-w-3xl">
        <Typography
            variant="h6"
            className="mb-4 text-lg lg:text-xl font-semibold"
        >
            {sectionTitle}
        </Typography>
        <Divider orientation="horizontal" />
        {selectedActivities.length > 0 ? (
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-4 pb-4 p-4">
                {selectedActivities.map((activity) => (
                    <ActivityCard
                        key={activity.id}
                        activity={activity}
                        onClick={onClick}
                        onDelete={onDelete}
                    />
                ))}
            </div>
        ) : (
            <Typography variant="body2" color="textSecondary">
                Ingen aktiviteter valgt for denne seksjonen.
            </Typography>
        )}
    </div>
);

export default SectionStepGrid;