import React, { useState } from "react";
import { Box, Card, CardContent, Typography } from "@mui/material";

interface CarouselCardProps {
  title: string;
  description: string;
  imageUrl: string;
  onClick?: () => void;
}

const CarouselCard: React.FC<CarouselCardProps> = ({
  title,
  description,
  imageUrl,
  onClick,
}) => {
  const defaultImageUrl =
    "https://via.placeholder.com/300x200?text=No+Image+Available";

  const [isHovered, setIsHovered] = useState(false);
  const [dragStartX, setDragStartX] = useState<number | null>(null);
  const [dragging, setDragging] = useState(false);
  const dragThreshold = 5; // Threshold in pixels to determine drag vs click

  const handleMouseDown = (event: React.MouseEvent) => {
    setDragStartX(event.clientX);
    setDragging(false);
  };

  const handleMouseMove = (event: React.MouseEvent) => {
    if (dragStartX !== null) {
      const dragDiff = Math.abs(event.clientX - dragStartX);
      if (dragDiff > dragThreshold) {
        setDragging(true);
      }
    }
  };

  const handleMouseUp = () => {
    if (!dragging && onClick) {
      onClick();
    }
    setDragging(false);
    setDragStartX(null);
  };

  return (
    <Box
      className="min-w-[300px]"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
    >
      <Card
        sx={{
          cursor: "pointer",
          transform: isHovered ? "scale(1.05)" : "scale(1)",
          transition: "transform 0.3s ease-in-out",
          boxShadow: isHovered ? "0 10px 20px rgba(0,0,0,0.2)" : "none",
        }}
      >
        <CardContent>
          <Typography variant="h6" component="h2">
            {title}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {description}
          </Typography>
        </CardContent>
        <Box
          sx={{
            width: "100%",
            height: 200,
            backgroundImage: `url(${imageUrl || defaultImageUrl})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        />
      </Card>
    </Box>
  );
};

export default CarouselCard;
