import React, { useEffect, useRef } from "react";
import Quill from "quill";
import "quill/dist/quill.snow.css";
import { Box, Typography } from "@mui/material";

interface QuillEditorProps {
  value: string;
  onChange: (value: string) => void;
}

export const QuillEditor: React.FC<QuillEditorProps> = ({
  value,
  onChange,
}) => {
  const editorRef = useRef<HTMLDivElement | null>(null);
  const quillRef = useRef<Quill | null>(null);

  useEffect(() => {
    if (editorRef.current && !quillRef.current) {
      quillRef.current = new Quill(editorRef.current, {
        theme: "snow",
        modules: {
          toolbar: [
            [{ header: "1" }, { header: "2" }],
            [{ list: "ordered" }, { list: "bullet" }],
            ["bold", "italic", "underline"],
          ],
        },
        placeholder: "Beskrivelse...",
      });

      quillRef.current.on("text-change", () => {
        onChange(quillRef.current?.root.innerHTML || "");
      });

      quillRef.current.root.innerHTML = value;
    }
  }, []);

  return (
    <Box
      ref={editorRef}
      sx={{
        borderRadius: "4px",
        minHeight: "200px",
        height: "100%",
        fontSize: "16px",
      }}
    />
  );
};

interface QuillViewerProps {
  content: string;
}

export const QuillViewer: React.FC<QuillViewerProps> = ({ content }) => {
  return (
    <Box>
      <Typography
        sx={{
          fontWeight: "bold",
          position: "relative",
          zIndex: 1,
        }}
      >
        Beskrivelse
      </Typography>
      <Box
        sx={{
          borderRadius: "4px",
          border: "1px solid #ccc",
          minHeight: "200px",
          padding: "12px",
          position: "relative",
          fontSize: "16px",
        }}
      >
        <Box dangerouslySetInnerHTML={{ __html: content }} />
      </Box>
    </Box>
  );
};
