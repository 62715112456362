import React, { createContext, useContext, useState, ReactNode } from "react";
import { RecordData } from "../pages/Sessions/types.ts";

interface FormContextType {
  title: string;
  setTitle: (title: string) => void;
  selectedCategory: string[];
  setSelectedCategory: (categories: string[]) => void;
  selectedBranches: string[];
  handleBranchChange: (branch: string) => void;
  sections: number;
  setSections: (sections: number) => void;
  thumbnailIndex: number;
  setThumbnailIndex: (sections: number) => void;
  sectionTitles: string[];
  setSectionTitles: (titles: string[]) => void;
  selectedActivities: RecordData[][];
  setSelectedActivities: (activities: RecordData[][]) => void;
  setDescription: (description: string) => void;
  description: string;
  images: ImageDataPost[];
  addImages: (event: React.ChangeEvent<HTMLInputElement>) => void;
  removeImage: (index: number) => void;
  error: string | null;
  setError: (error: string | null) => void;
}

const FormContext = createContext<FormContextType | undefined>(undefined);

interface FormProviderProps {
  children: ReactNode;
}

export interface ImageDataPost {
  file: File;
  isThumbnail: boolean;
}
interface ValidationErrors {
  title?: string;
  selectedCategory?: string;
  selectedBranches?: string;
  description?: string;
  images?: string;
}

interface FormContextType {
  title: string;
  setTitle: (title: string) => void;
  selectedCategory: string[];
  setSelectedCategory: (categories: string[]) => void;
  selectedBranches: string[];
  handleBranchChange: (branch: string) => void;
  sections: number;
  setSections: (sections: number) => void;
  thumbnailIndex: number;
  setThumbnailIndex: (sections: number) => void;
  sectionTitles: string[];
  setSectionTitles: (titles: string[]) => void;
  selectedActivities: RecordData[][];
  setSelectedActivities: (activities: RecordData[][]) => void;
  setDescription: (description: string) => void;
  description: string;
  images: ImageDataPost[];
  addImages: (event: React.ChangeEvent<HTMLInputElement>) => void;
  removeImage: (index: number) => void;
  errors: ValidationErrors;
  setErrors: (errors: ValidationErrors) => void;
  validateForm: () => boolean;
  step: number
  setActiveStep: (index: number) => void
}

export const FormProvider: React.FC<FormProviderProps> = ({ children }) => {
  const [title, setTitle] = useState<string>("");
  const [selectedCategory, setSelectedCategory] = useState<string[]>([]);
  const [selectedBranches, setSelectedBranches] = useState<string[]>([]);
  const [sections, setSections] = useState<number>(3);
  const [sectionTitles, setSectionTitles] = useState<string[]>([
    "Oppvarming",
    "Hoveddel",
    "Avslutning",
  ]);
  const [selectedActivities, setSelectedActivities] = useState<RecordData[][]>(
    Array(sections).fill([])
  );
  const [images, setImages] = useState<ImageDataPost[]>([]);
  const [thumbnailIndex, setThumbnailIndex] = useState<number>(0);
  const [description, setDescription] = useState<string>("");
  const [errors, setErrors] = useState<ValidationErrors>({});
  const [error, setError] = useState<string | null>(null); // Add error state
  const [step, setActiveStep] = useState(0);
  // Validation logic
  const validateForm = (): boolean => {
    console.log(step)
    let validationErrors: ValidationErrors = {};

    if (selectedBranches.length === 0 && step === 0) {
      validationErrors.selectedBranches = "Minst en gren må velges";
    }
    if (title.trim() === "" && step === 1) {
      validationErrors.title = "Tittel er påkrevd";
    }
    if (selectedCategory.length === 0 && step === 1) {
      validationErrors.selectedCategory = "Minst en kategori må velges";
    }
    if (description.trim() === "" && step === 1) {
      validationErrors.description = "Fyll inn beskrivelse.";
    }
    setErrors(validationErrors);


    // Return false if any validation errors exist
    console.log(Object.keys(validationErrors).length)
    return Object.keys(validationErrors).length === 0;
  };

  const addImages = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const fileArray = Array.from(e.target.files);
      const newImagePosts: ImageDataPost[] = fileArray.map((file: File, index: number) => ({
        file: file,
        isThumbnail: images.length === 0 && index === 0, // Set first image as thumbnail if no images exist
      }));
      setImages([...images, ...newImagePosts]);
    }
  };

  const handleBranchChange = (branch: string) => {
    if (selectedBranches.includes(branch)) {
      setSelectedBranches(selectedBranches.filter((b) => b !== branch));
    } else {
      setSelectedBranches([...selectedBranches, branch]);
    }
  };

  const removeImage = (index: number) => {
    const updatedImages = images.filter((_, i) => i !== index);
    setImages(updatedImages);
  };

  return (
    <FormContext.Provider
      value={{
        title,
        setTitle,
        selectedCategory,
        setSelectedCategory,
        selectedBranches,
        handleBranchChange,
        thumbnailIndex,
        setThumbnailIndex,
        sections,
        setSections,
        sectionTitles,
        setSectionTitles,
        selectedActivities,
        setSelectedActivities,
        addImages,
        removeImage,
        images,
        description,
        setDescription,
        errors, // Don't forget this field
        setErrors, // And this one too
        error, // Provide the error field
        setError, // Provide the setError function
        validateForm, // Ensure validateForm is provided
        step,
        setActiveStep,
      }}
    >
      {children}
    </FormContext.Provider>
  );
};
export const useFormContext = (): FormContextType => {
  const context = useContext(FormContext);
  if (!context) {
    throw new Error("useFormContext must be used within a FormProvider");
  }
  return context;
};