import { useCallback, useEffect, useRef, useState } from "react";
import { useFormContext } from "../../contexts/FormContext";
import { RecordData } from "./types";
import { useSearchContext } from "../../contexts/SearchContext";
import {
    Divider,
    TextField,
    Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { BACKEND_REST_URL } from "../../main";
import ActivityCard from "../../components/Sessions/ActivityCard";
import ActivityPickerDrawer from "../../components/Sessions/ActivityDrawer";
interface StepProps {
    step: number;
}

const SectionStep: React.FC<StepProps> = ({ step }) => {
    const {
        sectionTitles,
        setSectionTitles,
        selectedActivities,
        setSelectedActivities,
        setError,
    } = useFormContext();
    const { searchData, setSearchData } = useSearchContext();
    const [records, setRecords] = useState<RecordData[]>([]);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [skip, setSkip] = useState<number>(0);
    const [total, setTotal] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(false);
    const LIMIT = 20;

    const observerRef = useRef<IntersectionObserver | null>(null);
    const scrollContainerRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        setSkip(0);
        setRecords([]);
    }, [searchData]);

    const fetchRecords = useCallback(() => {
        const activityOrSession = "activities";
        const url = BACKEND_REST_URL + `/api/${activityOrSession}/search`;
        setLoading(true);
        fetch(`${url}?skip=${skip}&limit=${LIMIT}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(searchData),
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error("Network response was not ok " + response.statusText);
                }
                return response.json();
            })
            .then((data) => {
                setTotal(data["total"]);
                setRecords((prevRecords) =>
                    skip === 0
                        ? data[searchData.show_activities ? "activities" : "sessions"]
                        : [
                            ...prevRecords,
                            ...data[searchData.show_activities ? "activities" : "sessions"],
                        ]
                );
            })
            .catch((error) => setError("Error fetching data: " + error.message))
            .finally(() => setLoading(false));
    }, [searchData, skip]);

    useEffect(() => {
        fetchRecords();
    }, [fetchRecords]);

    const lastRecordElementRef = useCallback(
        (node: HTMLElement | null) => {
            if (loading) return;

            if (observerRef.current) observerRef.current.disconnect();

            observerRef.current = new IntersectionObserver((entries) => {
                if (entries[0].isIntersecting && records.length < total) {
                    setSkip((prevSkip) => prevSkip + LIMIT);
                }
            });

            if (node) observerRef.current.observe(node);
        },
        [loading, records, total]
    );

    const handleSectionTitleChange =
        (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
            const newSectionTitles = [...sectionTitles];
            newSectionTitles[index] = event.target.value;
            setSectionTitles(newSectionTitles);
        };

    const handleSelectActivity = (sectionIndex: number, activity: RecordData) => {
        const newSelectedActivities = [...selectedActivities];
        newSelectedActivities[sectionIndex] = [
            ...newSelectedActivities[sectionIndex],
            activity,
        ];
        setSelectedActivities(newSelectedActivities);
    };

    const handleDeleteActivity = (sectionIndex: number, activity: RecordData) => {
        let newSelectedActivities = [...selectedActivities];
        newSelectedActivities[sectionIndex] = newSelectedActivities[
            sectionIndex
        ].filter((x) => x.id != activity.id);
        setSelectedActivities(newSelectedActivities);
    };

    const openActivityPicker = () => setDrawerOpen(true);
    const closeActivityPicker = () => setDrawerOpen(false);

    const deleteActivity = (stepIndex: number, activity: any) =>
        handleDeleteActivity(stepIndex, activity);

    function handleSearchTextChange(searchText: string): void {
        setSearchData({
            ...searchData,
            plaintext: searchText,
            show_activities: true,
        });
    }

    return (
        <>
            <div className="container mx-auto p-4 lg:p-6 max-w-3xl">
                <Typography
                    variant="h6"
                    className="mb-4 text-lg lg:text-xl font-semibold"
                >
                    Seksjon {step}
                </Typography>

                <TextField
                    variant="outlined"
                    fullWidth
                    value={sectionTitles[step - 1]}
                    onChange={handleSectionTitleChange(step)}
                    placeholder={`Tittel for seksjon ${step}`}
                    className="mb-6"
                />
                <Divider orientation="horizontal" className="" />

                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-4 pb-4 p-4">
                    {selectedActivities[step - 1].map((activity) => (
                        <ActivityCard
                            key={activity.id}
                            activity={activity}
                            onDelete={() => deleteActivity(step - 1, activity)}
                        />
                    ))}

                    <div
                        className="bg-gray-100 rounded-lg shadow-md p-4 flex items-center justify-center cursor-pointer hover:bg-gray-200"
                        onClick={openActivityPicker}
                    >
                        <AddIcon className="text-gray-600 text-4xl" />
                    </div>
                </div>
                <ActivityPickerDrawer
                    open={drawerOpen}
                    onClose={closeActivityPicker}
                    records={records}
                    selectedActivities={selectedActivities[step - 1]}
                    handleSelectActivity={(activity) =>
                        handleSelectActivity(step - 1, activity)
                    }
                    handleDeleteActivity={(activity) =>
                        handleDeleteActivity(step - 1, activity)
                    }
                    lastRecordElementRef={lastRecordElementRef}
                    loading={loading}
                    handleSearchTextChange={handleSearchTextChange}
                    scrollContainerRef={scrollContainerRef} />
            </div>
        </>
    );
};

export default SectionStep;
