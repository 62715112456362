import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  Dispatch,
  SetStateAction,
} from "react";

interface SearchData {
  plaintext: string;
  branch: string[];
  environment: string[];
  skillevel: string[];
  categories: string[];
  focusGroup: string[];
  disabilities?: string[] | null;
  show_activities: boolean;
}

interface SearchContextType {
  searchData: SearchData;
  setSearchData: Dispatch<SetStateAction<SearchData>>;
}

// Create the context with default values
const SearchContext = createContext<SearchContextType | undefined>(undefined);

// Create a provider component
interface SearchProviderProps {
  children: ReactNode;
}

export const SearchProvider: React.FC<SearchProviderProps> = ({ children }) => {
  const [searchData, setSearchData] = useState<SearchData>({
    plaintext: "",
    branch: [],
    environment: [],
    skillevel: [],
    categories: [],
    focusGroup: [],
    disabilities: null,
    show_activities: false,
  });

  return (
    <SearchContext.Provider value={{ searchData, setSearchData }}>
      {children}
    </SearchContext.Provider>
  );
};

// Custom hook to use the SearchContext
export const useSearchContext = (): SearchContextType => {
  const context = useContext(SearchContext);
  if (!context) {
    throw new Error("useSearchContext must be used within a SearchProvider");
  }
  return context;
};
