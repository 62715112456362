import React from 'react';
import { Dialog, DialogContent, DialogActions, Button, } from '@mui/material';
import { ActivityForm } from '../createActivity/ActivityForm';


import SessionForm from '../Sessions/SessionForm';
import { RecordData, SessionRecordData } from '../../pages/Sessions/types';
import { useSearchContext } from '../../contexts/SearchContext';

interface ActivityModalProps {
    open: boolean;
    onClose: () => void;
    record: RecordData | SessionRecordData;
}

const ActivityModal: React.FC<ActivityModalProps> = ({ open, onClose, record }) => {
    // const [images, setImages] = useState<string[]>([]);
    // const handleSubmit = (e: React.FormEvent) => {
    //     e.preventDefault();
    // };

    // const formattedDate = (dateString: string) => {
    //     const date = new Date(dateString);
    //     return date.toLocaleString(); // Format the date as you prefer
    // };
    const { searchData } = useSearchContext();

    return (
        <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
            <DialogContent>
                {/* Display the activity form */}
                {searchData.show_activities ? <ActivityForm
                    activity={record as RecordData}
                    readOnly={true}
                /> : <SessionForm record={record as SessionRecordData} />}


                {/* User info styled like Stack Overflow */}
                {/* {record.owner && (
                    <Box sx={{ display: 'flex', alignItems: 'center', mt: 2, borderTop: '1px solid #e0e0e0', pt: 2 }}>
                        <Avatar alt={record.owner.name} src={record.owner.avatarUrl} sx={{ width: 48, height: 48 }} />
                        <Box sx={{ ml: 2 }}>
                            <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
                                {record.owner.name}
                            </Typography>
                            {record.createdAt && (
                                <Typography variant="caption" color="textSecondary">
                                    Published on: {formattedDate(record.createdAt)}
                                </Typography>
                            )}
                        </Box>
                    </Box>
                )} */}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Lukk
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ActivityModal;
