import React from "react";
import { Container, Box } from "@mui/material";
import RecordList from "../components/RecordList";
import SearchSlideContainer from "../components/SearchSlideContainer";
import { useScreenMode } from "../contexts/ScreenModeContext";

const Home: React.FC = () => {
  // const data = new Array(12).fill(null).map(() => ({ ...dummyData[0] }));
  const { isMobile } = useScreenMode();

  const commonContent = // @vetle: du kan dra den her ut i egen component om du vil.
    (
      <>
        <SearchSlideContainer />
        <RecordList />
      </>
    );

  return (
    <Box>
      <Box sx={isMobile ? { mt: 14 } : {}}>
        {isMobile ? ( // @Vetle: Kanskje du ser en mer nice måte å gjøre det her på
          <Box component="main" maxWidth="lg" sx={{ mt: 2 }}>
            {commonContent}
          </Box>
        ) : (
          <Container component="main" maxWidth="lg" sx={{ mt: 2 }}>
            {commonContent}
          </Container>
        )}
      </Box>
    </Box>
  );
};

export default Home;
