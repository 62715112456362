import React, { useEffect, useState } from "react";
import { Box, Typography, Divider } from "@mui/material";
import { SessionRecordData } from '../../pages/Sessions/types';
import CategoryList from "./SessionRender/CategoryList";
import ImagePreview from "./SessionRender/ImagePreview";
import SectionCount from "./SessionRender/SectionCount";
import SectionStepGrid from "./sectionStepGrid";
import { BACKEND_REST_URL } from "../../main";
import fallback_img from '../../assets/fallback_img.webp';

interface RecordCardProps {
    record: SessionRecordData;
}

const SessionForm: React.FC<RecordCardProps> = ({ record }) => {
    const UID = record.id;
    const [imageSrc, setImageSrc] = useState<string>(fallback_img); // Default to fallback image

    useEffect(() => {
        const fetchThumbnail = async () => {
            try {
                const responseThumbnail = await fetch(BACKEND_REST_URL + `/api/session/${UID}/images/thumbnail`);
                if (responseThumbnail.ok) {
                    const data = await responseThumbnail.json();
                    if (data.id) {
                        const imageResponse = await fetch(BACKEND_REST_URL + `/api/image/${data.id}`);
                        if (imageResponse.ok) {
                            setImageSrc(BACKEND_REST_URL + `/api/image/${data.id}`);
                        } else {
                            console.error('Failed to stream the image');
                        }
                    }
                } else {
                    console.error('Failed to fetch thumbnail ID, using fallback');
                }
            } catch (error) {
                console.error('Error fetching image:', error);
            }
        };
        fetchThumbnail();
        console.log(imageSrc)
    }, [UID]);



    return (
        <Box component="div" className="container mx-auto p-4 lg:p-6">
            <div className="container mx-auto p-4 lg:p-6">
                <div className="text-center mb-6 lg:mb-8">
                    <Typography variant="h5" className="font-semibold text-lg lg:text-2xl">
                        {record.title}
                    </Typography>
                </div>

                <Divider className="py-4 my-6 lg:my-8">
                    <b>Informasjon</b>
                </Divider>

                <div className="grid  grid-cols-2 lg:grid-cols-2 gap-4 lg:gap-6">
                    <CategoryList categories={record.categories} />
                    <div className=" row-span-2">
                        <ImagePreview images={imageSrc} />
                    </div>
                    <SectionCount sections={record.sections.length} />
                </div>

                <Divider className="py-4 mt-6 lg:mt-8">
                    <b>Øvelser</b>
                </Divider>

                {/* Render each section and its activities */}
                {record.sections.map((section, index) => (
                    <SectionStepGrid
                        key={index}
                        sectionTitle={section.title}
                        selectedActivities={section.activities}

                    />
                ))}

            </div>
        </Box>
    );
};

export default SessionForm;